const header = document.querySelector("._header");
const openButton = document.querySelector(".js-header-open-button");
const closeButton = document.querySelector(".js-header-close-button");
const navLinks = document.querySelectorAll(".js-nav-link");

const toggleHeaderMenu = function () {
  if (header && openButton && closeButton && navLinks.length > 0) {
    const toggleMenu = () => {
      header.classList.toggle("js-header-active");
    };

    const closeMenu = () => {
      if (document.documentElement.clientWidth >= 1280) {
        header.classList.remove("js-header-active");
      }
    };

    window.addEventListener("resize", closeMenu);
    openButton.addEventListener("click", toggleMenu);
    closeButton.addEventListener("click", toggleMenu);
    navLinks.forEach(navLink => {
      navLink.addEventListener("click", toggleMenu);
    } )
  }
};

export default toggleHeaderMenu;
