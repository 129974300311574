import Swiper from "swiper/swiper-bundle.esm.browser";
import "swiper/swiper-bundle.min.css";

const initTeamSwiper = function () {
  new Swiper(".js-team-swiper", {
    slidesPerView: 1,
    spaceBetween: 32,
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 62,
        pagination: {
          el: ".js-team-pagination",
          type: "bullets",
          clickable: "true",
        },
      },
    },
    navigation: {
      nextEl: ".team-swiper__button--next",
      prevEl: ".team-swiper__button--prev",
    },
  });
};

export default initTeamSwiper;
