import inView from "in-view";

const bullets = document.querySelectorAll(".js-sections-pagination__bullet");

const inViewSection = (sectionSelector) => {
  inView(sectionSelector)
    .on("enter", function (e) {
      const section = e.getAttribute("data-section");

      if (section) {
        const currentBullet = document.querySelector(
          '.js-sections-pagination__bullet a[href="#' + section + '"]'
        ).parentNode;

        bullets.forEach((el) =>
          el.classList.remove("js-sections-pagination__bullet--active")
        );
        currentBullet.classList.add("js-sections-pagination__bullet--active");
      }
    })
    .on("exit", function (e) {
      const section = e.getAttribute("data-section");
      const currentBullet = document.querySelector(
        '.js-sections-pagination__bullet a[href="#' + section + '"]'
      ).parentNode;

      currentBullet.classList.remove("js-sections-pagination__bullet--active");

      // bullets.forEach((el) =>{
      //   el.classList.remove("js-sections-pagination__bullet--active")
      // });
    });
};

const initSectionsPagination = () => {
  inViewSection("#intro");
  inViewSection("#services");
  inViewSection("#reglament");
  inViewSection("#exceptions");
  inViewSection("#advantages");
  inViewSection("#form");
  inViewSection("#team");
  inViewSection("#contacts");
};

export default initSectionsPagination;
