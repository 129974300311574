import Swiper from "swiper/swiper-bundle.esm.browser";
import "swiper/swiper-bundle.min.css";

const initAdvantagesSwiper = function () {
  new Swiper(".js-advantages-swiper", {
    slidesPerView: 1,
    spaceBetween: 20,
    loop: true,
    navigation: {
      nextEl: ".advantages-swiper__button--next",
      prevEl: ".advantages-swiper__button--prev",
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
    }
  });
};

export default initAdvantagesSwiper;