const initYandexMap = () => {
  ymaps.ready(function () {
    var myMap = new ymaps.Map("map", {
      center: [55.762520884077254, 37.769880113684934],
      zoom: 16,
    });

    var myPlacemark1 = new ymaps.Placemark(
      [55.762557698809125, 37.776136617332624],
      {},
      {
        iconLayout: "default#image",
        // Своё изображение иконки метки.
        iconImageHref: require('../img/map-pin-icon.svg'),
        // Размеры метки.
        iconImageSize: [41, 55],
        // Смещение левого верхнего угла иконки относительно
        // её "ножки" (точки привязки).
        iconImageOffset: [-20, -55],
      }
    );

    myMap.geoObjects.add(myPlacemark1);
    myMap.behaviors.disable("scrollZoom");
  });
};

export default initYandexMap;
