import jQuery from 'jquery'
import MicroModal from "micromodal";

import initYandexMap from "./js/initYandexMap";
import initServicesSwiper from "./js/initServicesSwiper";
import initAdvantagesSwiper from "./js/initAdvantagesSwiper";
import initTeamSwiper from "./js/initTeamSwiper";
import toggleHeaderMenu from "./js/toggleHeaderMenu";
import initSectionsPagination from './js/initSectionsPagination';

window.$ = window.jquery = window.jQuery = jQuery

document.addEventListener("DOMContentLoaded", function () {
  initYandexMap();
  initServicesSwiper();
  initAdvantagesSwiper();
  initTeamSwiper();
  toggleHeaderMenu();
  MicroModal.init();
  initSectionsPagination();
});


