import Swiper from "swiper/swiper-bundle.esm.browser";
import "swiper/swiper-bundle.min.css";


const initServicesSwiper = function () {
  new Swiper(".js-services-swiper", {
    slidesPerView: 1,
    spaceBetween: 12,
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
    },
    navigation: {
      nextEl: ".services-swiper__button--next",
      prevEl: ".services-swiper__button--prev",
    },
  });
};

export default initServicesSwiper;
